import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import YoutubeVideo from "../components/youtube"

const DemoReelPage = () => (
  <Layout pageName="Demo Reel" >
    <SEOComponent  title="Demo Reel" />
    <div className="columns m-0 pt-4">
      <div className="column">
        <YoutubeVideo videoSrcURL="https://www.youtube.com/embed/hGvJCkgtLHU" videoTitle="demoReel2016" />
      </div>
      <div className="column">
        <p>Paul O’Brien’s 3D Demo Reel, featuring characters Flem from “Super New City”, Space Marine from “Gun Machine”, Bug Boss from San Diego Game Jam 2016 entrant “Swarm 1.0”, and Witch #2 from indie game “Witches Be Crazy”.</p>
      </div>
    </div>
  </Layout>
)

export default DemoReelPage