import React from "react"

const YoutubeVideo = ({ videoSrcURL, videoTitle }) => {
  return (
    <figure
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%",
        margin: "0",
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
        }}
        title={videoTitle}
        width="560"
        height="315"
        src={videoSrcURL}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      >
      </iframe>
    </figure>
  )
}

export default YoutubeVideo